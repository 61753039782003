import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Button from '../../../global/atoms/button';
import Close from '../../../../resources/images/close.svg';
import useMedia from '../../../../hooks/useMedia';
import './reviewDuplicates.scss';
import { checkoutDatalocator } from '../../checkoutAndOrderSummary/dataLocators';
import SideDrawerModal from '../../../global/modules/modals/SideDrawerModal';
import CardRadioButton from '../../../global/atoms/cardRadioButton';
import { LoadingIndicator } from '../../../../aem-core-components';

const ReviewDuplicates = props => {
    const {
        newProject,
        duplicates,
        isNewProject,
        handleClose,
        createProject,
        onPressProject,
        showReviewDuplicates,
        setShowReviewDuplicates,
        isCreateProjectLoading
    } = props;
    let mediaTypeMobile = useMedia();
    mediaTypeMobile = mediaTypeMobile === 'MOBILE' ? true : false;
    const intl = useIntl();
    const [selectedValue, setSelectedValue] = useState(newProject?.jobNumber);
    const createProjectCTA = () => {
        return (
            <div>
                <Button
                    type="button"
                    className={'button button-primary button-block'}
                    onClick={createProject}
                    data-testid={
                        isNewProject === true
                            ? checkoutDatalocator.duplicateCreateProjectCta
                            : checkoutDatalocator.duplicateUseExistingProjectCta
                    }>
                    {isNewProject === true
                        ? intl.formatMessage({ id: 'checkout:review-duplicates-create-project' })
                        : intl.formatMessage({ id: 'checkout:review-duplicates-use-existing-project' })}
                </Button>
            </div>
        );
    };

    const getStreetAddress = data => {
        return `${data?.city?.toLowerCase()}, ${data?.state} ${data?.zip}`;
    };

    const reviewDuplicatesDrawerHeading = () => {
        return (
            <div className={`review-duplicates-drawer__container ${isCreateProjectLoading ? `clicks-disabled` : ''}`}>
                <h5
                    className="review-duplicates-drawer__head__heading"
                    data-testid={checkoutDatalocator.reviewDuplicateHeading}>
                    {intl.formatMessage({ id: 'checkout:review-duplicates-title' })}
                </h5>
                <Button
                    data-testid={checkoutDatalocator.duplicateReviewCloseIcon}
                    className="review-duplicates-drawer__close-icon"
                    onClick={handleClose}
                    tabIndex={0}>
                    <Close aria-hidden="true" tabIndex={'-1'} />
                </Button>
            </div>
        );
    };
    const cardStyle = data => {
        return (
            <div className="review-duplicates-drawer__card">
                {selectedValue === data?.jobNumber && (
                    <div className="card__tag">{intl.formatMessage({ id: 'common:selected' })}</div>
                )}
                <span
                    className="review-duplicates-drawer__card__title"
                    data-testid={checkoutDatalocator.duplicateProjectName}>
                    {data?.jobName}
                </span>
                <small
                    className="review-duplicates-drawer__card-content review-duplicates-drawer__card-content-ellipsis"
                    data-testid={checkoutDatalocator.duplicateProjectAddress}>
                    {data?.address1}
                </small>
                {data?.address2?.length > 0 && (
                    <small
                        className="review-duplicates-drawer__card-content review-duplicates-drawer__card-content-ellipsis"
                        data-testid={checkoutDatalocator.duplicateProjectAddress}>
                        {data?.address2}
                    </small>
                )}
                <small
                    className="review-duplicates-drawer__card-content"
                    data-testid={checkoutDatalocator.duplicateProjectAddress}>
                    {getStreetAddress(data)}
                </small>
            </div>
        );
    };

    const duplicateCardStyle = data => {
        return (
            <div className="review-duplicates-drawer__card">
                {selectedValue === data?.projectId && (
                    <div className="card__tag">{intl.formatMessage({ id: 'common:selected' })}</div>
                )}
                <span
                    className="review-duplicates-drawer__card__title"
                    data-testid={checkoutDatalocator.duplicateProjectName}>
                    {data?.projectName}
                </span>
                <small
                    className="review-duplicates-drawer__card-content review-duplicates-drawer__card-content-ellipsis"
                    data-testid={checkoutDatalocator.duplicateProjectAddress}>
                    {data?.address[0]?.line1?.toLowerCase()}
                </small>
                {data?.address[0]?.line2?.length > 0 && (
                    <small
                        className="review-duplicates-drawer__card-content review-duplicates-drawer__card-content-ellipsis"
                        data-testid={checkoutDatalocator.duplicateProjectAddress}>
                        {data?.address[0]?.line2?.toLowerCase()}
                    </small>
                )}
                <small
                    className="review-duplicates-drawer__card-content"
                    data-testid={checkoutDatalocator.duplicateProjectAddress}>
                    {getStreetAddress(data?.address[0])}
                </small>
            </div>
        );
    };
    const reviewDuplicatesDrawerContent = () => {
        return (
            <>
                {' '}
                <div className="review-duplicates-drawer__head">
                    <span className="review-duplicates-drawer__head__sub-heading">
                        {intl.formatMessage({ id: 'checkout:review-duplicates-similar-project-title' })}
                    </span>
                    <h6
                        className="review-duplicates-drawer__head__entered-heading"
                        data-testid={checkoutDatalocator.youEnteredLabel}>
                        {intl.formatMessage({ id: 'checkout:review-duplicates-you-entered' })}
                    </h6>
                    <CardRadioButton
                        item={newProject}
                        cardStyle={cardStyle}
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                        onPressRadioButton={onPressProject}
                        isProject={true}
                        radioBtnDataLocator={checkoutDatalocator.radioButton}
                        cardStyleButtonWrapper="card_button_alignment"
                    />
                </div>
                <div className={`review-duplicates-drawer__list-container ${duplicates && `mobile-view`}`}>
                    <h6
                        className="review-duplicates-drawer__list-container__heading"
                        data-testid={checkoutDatalocator.duplicatePossibleProjectDuplicates}>
                        {intl.formatMessage({ id: 'checkout:review-duplicates-possible-duplicates' })}
                    </h6>
                    {isCreateProjectLoading && (
                        <div className={'orderSummaryLoader'}>
                            <LoadingIndicator />
                        </div>
                    )}
                    <div className="review-duplicates-drawer__list-content">
                        {duplicates?.map(item => {
                            return (
                                <div className="review-duplicates-drawer__radio-container" key={item?.projectId}>
                                    <CardRadioButton
                                        item={item}
                                        cardStyle={duplicateCardStyle}
                                        selectedValue={selectedValue}
                                        setSelectedValue={setSelectedValue}
                                        onPressRadioButton={onPressProject}
                                        isDuplicateProjects={true}
                                        radioBtnDataLocator={checkoutDatalocator.radioButton}
                                        cardStyleButtonWrapper="card_radio_view"
                                        isSelectionRequired={false}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <SideDrawerModal
                isModalOpen={showReviewDuplicates}
                handleModalToggle={setShowReviewDuplicates}
                header={reviewDuplicatesDrawerHeading()}
                content={reviewDuplicatesDrawerContent()}
                modalContentClass="review-duplicates-drawer__content"
                footer={createProjectCTA()}
                showModalInRight={true}
                modalFooterClass="modal_background"
                customModalClass="modal_review-duplicate"
                modalHeaderClass="review-duplicates-drawer__header"
            />
        </>
    );
};

export default ReviewDuplicates;
