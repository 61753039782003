import React, { memo, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { LoadingIndicator } from '../../../aem-core-components';
import {
    SET_CONSUMABLES_SELECTED_ITEMS,
    SET_SELECTED_RENTAL_EQUIPMENT,
    SET_SHOW_RENTAL_EQUIPMENT_SCREEN,
    UPDATE_CONSUMABLES
} from '../../../aem-core-components/actions/constants';
import { TOOLTIP_MODAL } from '../../../aem-core-components/context/Modal/constants';
import { ModalContext } from '../../../aem-core-components/context/ModalContext';
import makeUrl from '../../../aem-core-components/utils/makeUrl';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../constants/analyticsConstants/Ecommerce';
import { EVENT_P2P_NAMES_CONFIG } from '../../../constants/analyticsConstants/ProcureToPay';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import { useCartState } from '../../../contexts/cart';
import useAnalytics from '../../../hooks/analytics/useAnalytics';
import { useCheckAuthorityType, useCheckUser } from '../../../hooks/useCheckUser';
import useCheckout from '../../../hooks/useCheckout';
import ArrowRight from '../../../resources/images/arrow-right.svg';
import CheckMarkCircle from '../../../resources/images/checkmark-circle.svg';
import InfoIcon16 from '../../../resources/images/info16.svg';
import useCartEstimate from '../../App/hooks/useCartEstimate';
import { useFilterState } from '../../cap';
import AlertModalWithButtons from '../../global/atoms/AlertModalWithButtons';
import Button from '../../global/atoms/button/button';
import HelplineNumber from '../../global/atoms/helplineNumber/HelplineNumber';
import { AUTHORITY_TYPE } from '../../global/constants';
import ContentCard from '../../global/modules/contentCard/ContentCard';
import { EditableView } from '../../global/modules/stepForm';
import { checkIsEditQuoteFlow, currencyToLocale } from '../../global/utils/commonUtils';
import { isValidString, logError } from '../../global/utils/logger';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';
import { FORCE_ITEM } from '../constants';
import { getTotalFromConsumables } from '../utils/checkoutUtils';
import './accessoriesAndAddons.scss';

const EditAccessoriesAndAddons = ({
    handleStepChange,
    currentStep,
    formStep,
    isCustomAccount,
    isAccessoriesAddonsAvailable
}) => {
    const [isSkipAlertModalOpen, setIsSkipAlertModalOpen] = useState(false);
    const [{ cart, consumables, optionalPlan, isLoading }, dispatch] = useCartState();
    const [{ viewCart }, filterDispatch] = useFilterState();
    const { selected } = consumables;
    const intl = useIntl();
    const imageWidth = 56;
    const imageHeight = 56;
    const userType = useCheckUser();
    const {
        sendEventsForClick,
        sendEventsForUpdateVirtualPath,
        sendEventsForEcommerceAction,
        sendEventsForP2PCtaClick
    } = useAnalyticsContext();
    const [{ getEstimates }] = useCartEstimate();
    const [{ payloadEcommerceActionAnalytics }] = useAnalytics();
    const { getCheckoutStepDetails } = useCheckout();
    const useModalState = () => useContext(ModalContext);
    const { openModal } = useModalState();
    const phoneNumber = ENV_CONFIG.PHONE_NUMBER;
    const authorityType = useCheckAuthorityType();
    const isCreateJobsite = isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREATEJOBSITE));
    const checkoutNextStep =
        authorityType === AUTHORITY_TYPE.P2P ? formStep.TRANSMIT_OR_SAVE : formStep.PROJECT_DETAILS;
    const isP2PUser = authorityType === AUTHORITY_TYPE.P2P;

    const isAccessoriesAddonsSelected = () => {
        let accessoryAndAddonSelected = false;
        for (let i in selected) {
            if (
                (selected[i]?.rentals && Object.keys(selected[i].rentals).length > 0) ||
                (selected[i]?.addons && Object.keys(selected[i].addons).length > 0)
            ) {
                accessoryAndAddonSelected = true;
            }
        }
        return accessoryAndAddonSelected;
    };

    const isRentalsOrAddonsSelected = isAccessoriesAddonsSelected();
    const isAccessoriesAddonsAvailableFlag = isAccessoriesAddonsAvailable();

    const stepChange = () => {
        let nextStep;
        if (userType !== USER_TYPE.CREDIT) {
            nextStep =
                optionalPlan.isRPPCheckboxVisible || optionalPlan.isFuelCheckboxVisible
                    ? formStep.OPTIONAL_PLANS
                    : formStep.PAYMENT;
        } else {
            if (optionalPlan.isRPPCheckboxVisible || optionalPlan.isFuelCheckboxVisible) {
                nextStep = formStep.OPTIONAL_PLANS;
            } else if (isCreateJobsite) {
                nextStep = currentStep + 1;
            } else {
                nextStep = checkoutNextStep;
            }
        }
        handleStepChange(nextStep);
    };

    useEffect(() => {
        if (!isAccessoriesAddonsAvailable()) {
            stepChange();
        }
    }, []);
    useEffect(() => {
        if (isAccessoriesAddonsAvailableFlag) {
            if (Object.keys(consumables?.selected).length === 0) {
                // when editing accessories and addons consumables?.selected should not be empty and contains forceItems
                processConsumablesWithAccessories();
            }
        }
    }, [isAccessoriesAddonsAvailableFlag]);

    const updateEquipmentInfo = (cartItem, consumableItem) => {
        try {
            const catID = parseInt(cartItem?.product?.sku.substring(0, 3));
            const classID = parseInt(cartItem?.product?.sku.substring(3, 7));

            if (catID === parseInt(consumableItem?.cat) && classID === parseInt(consumableItem?.class)) {
                consumableItem.equipmentInfo = {
                    sku: cartItem?.product?.sku,
                    name: cartItem?.product?.name,
                    qty: cartItem?.quantity
                };
            }
        } catch (error) {
            logError(error, false, 'updateEquipmentInfo');
        }
    };

    const processForceAddons = (item, forceAddons, forceItemList, consumableWithAccessories) => {
        try {
            if (
                item?.forceItem === FORCE_ITEM.YES &&
                consumables?.rentablesWithPrice[`${item?.itemNumber}${item?.stockClass}`]
            ) {
                forceAddons[`${item?.itemNumber}${item?.stockClass}${item?.oemStockNumber}`] = {
                    itemObj: item,
                    name: item?.itemDescription,
                    price: consumables?.rentablesWithPrice[`${item?.itemNumber}${item?.stockClass}`],
                    qty: consumableWithAccessories?.equipmentInfo?.qty,
                    isForced: true
                };
                forceItemList[consumableWithAccessories?.equipmentInfo?.sku] = {
                    addons: forceAddons,
                    rentals: {
                        ...forceItemList[consumableWithAccessories?.equipmentInfo?.sku]?.rentals
                    }
                };
            }
        } catch (error) {
            logError(error, false, 'processForceAddons');
        }
    };

    const processForceRentals = (rentable, forceRentals, forceItemList, consumableWithAccessories) => {
        try {
            if (rentable?.forceItem === FORCE_ITEM.YES) {
                forceRentals[rentable?.product?.sku] = {
                    itemObj: rentable,
                    name: rentable?.description,
                    price: consumables?.rentablesWithPrice[rentable?.product?.sku],
                    qty: consumableWithAccessories?.equipmentInfo?.qty,
                    rentalClass: rentable?.rentalClass,
                    isForced: true
                };
                forceItemList[consumableWithAccessories?.equipmentInfo?.sku] = {
                    rentals: forceRentals,
                    addons: {
                        ...forceItemList[consumableWithAccessories?.equipmentInfo?.sku]?.addons
                    }
                };
            }
        } catch (error) {
            logError(error, false, 'processForceRentals');
        }
    };

    const processConsumablesWithAccessories = () => {
        let forceAddons = {};
        let forceRentals = {};
        let forceItemList = {};

        try {
            const consumablesWithAccessories = consumables?.data?.map(consumableItem => {
                if (consumableItem?.consumables?.length > 0 || consumableItem?.rentables?.length > 0) {
                    cart?.items?.forEach(cartItem => {
                        updateEquipmentInfo(cartItem, consumableItem);
                    });
                }
                return consumableItem;
            });

            if (isP2PUser) {
                consumablesWithAccessories?.forEach(consumableWithAccessories => {
                    consumableWithAccessories.consumables?.forEach(consumable => {
                        consumable?.specGroups?.forEach(specGroup => {
                            specGroup?.items?.forEach(item => {
                                processForceAddons(item, forceAddons, forceItemList, consumableWithAccessories);
                            });
                        });
                    });
                    forceAddons = {};
                    consumableWithAccessories.rentables?.forEach(rentable => {
                        processForceRentals(rentable, forceRentals, forceItemList, consumableWithAccessories);
                    });
                    forceRentals = {};
                });

                dispatch({
                    type: SET_CONSUMABLES_SELECTED_ITEMS,
                    selectedItems: forceItemList
                });
            }
        } catch (error) {
            logError(error, false, 'processConsumablesWithAccessories');
        }
    };

    const sendEvents = () => {
        try {
            sendEventsForUpdateVirtualPath(
                VARIABLE_CONFIG.VIRTUAL_PAGE_TITLE.CHECKOUT_ACCESSORIES,
                `/${
                    localStorage.getItem('companyID') == 2 ? VARIABLE_CONFIG.REGION.CANADA : VARIABLE_CONFIG.REGION.US
                }/${VARIABLE_CONFIG.VIRTUAL_PAGE_URL.ACCESSOSIERS}`
            );
        } catch (error) {
            logError(error, false, 'sendEvents');
        }
    };

    const onPressProductCard = data => {
        dispatch({ type: SET_SELECTED_RENTAL_EQUIPMENT, selectedRentalEquipment: data });
        dispatch({ type: SET_SHOW_RENTAL_EQUIPMENT_SCREEN, flag: true });
    };

    const ecommerceAnalytics = () => {
        const isEditQuoteFlow = checkIsEditQuoteFlow();
        const displayQuoteId = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.DISPLAY_QUOTE_ID) || '';
        isEditQuoteFlow &&
            sendEventsForP2PCtaClick(
                EVENT_P2P_NAMES_CONFIG.P2P_CTA_CLICKED,
                EVENT_P2P_NAMES_CONFIG.P2P_LINK_NAME_SAVE_AND_CONTINUE,
                EVENT_P2P_NAMES_CONFIG.P2P_LINK_TYPE_ANCHOR,
                EVENT_P2P_NAMES_CONFIG.P2P_LINK_LOCATION_BODY,
                EVENT_P2P_NAMES_CONFIG.P2P_LINK_DESTINATION_SAVE_AND_CONTINUE,
                { quote_id: displayQuoteId }
            );
        const additionalPayload = {
            link_name: VARIABLE_CONFIG.EVENT_LABEL.SAVE_AND_CONTINUE,
            link_type: VARIABLE_CONFIG.TYPE.BUTTON,
            ...getCheckoutStepDetails(formStep, currentStep)
        };
        sendEventsForEcommerceAction(
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FORM_SUBMITTED,
            payloadEcommerceActionAnalytics(),
            additionalPayload
        );
    };

    const onContinue = async () => {
        ecommerceAnalytics();
        sendEvents();
        await getEstimates('', 0, '', '', false, cart?.availableCartItems);
        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.IS_ESTIMATES_LOADING);
        let accessoriesObj = getTotalFromConsumables(selected);
        dispatch({ type: UPDATE_CONSUMABLES, key: 'orderSummaryRentals', value: { ...consumables.selected } });
        dispatch({ type: UPDATE_CONSUMABLES, key: 'selectedRentalQtyAndPrice', value: { ...accessoriesObj } });
        dispatch({ type: UPDATE_CONSUMABLES, key: 'saveAndContinue', value: true });
        stepChange();
    };
    const renderBottomContentHandler = product => {
        let showBottomContent = false;
        let rentalList = {};
        let addonsList = {};

        Object.keys(consumables?.selected).forEach(item => {
            const catID = Number(item?.substring(0, 3));
            const classID = Number(item?.substring(3, 7));

            if (catID.toString() === product?.cat && classID.toString() === product?.class) {
                let selectedArray = Object.keys(consumables?.selected[item]);

                if (selectedArray.includes('rentals')) {
                    rentalList = Object.values(consumables?.selected[item]['rentals']);
                    if (Object.keys(rentalList).length > 0) {
                        showBottomContent = true;
                    }
                }

                if (selectedArray.includes('addons')) {
                    addonsList = Object.values(consumables?.selected[item]['addons']);
                    if (Object.keys(addonsList).length > 0) {
                        showBottomContent = true;
                    }
                }
            }
        });
        return { showBottomContent, rentalList, addonsList };
    };

    const getTotalCount = product => {
        try {
            if (isP2PUser) {
                //finding accessories count without forceditem
                let rentalsCount = 0;
                product?.rentables?.forEach(rentalItem => {
                    if (rentalItem?.forceItem === FORCE_ITEM.NO) {
                        rentalsCount += 1;
                    }
                });
                //finding addons count without forceditem
                let addonsCount = 0;
                product?.consumables?.forEach(addon => {
                    addon?.specGroups?.forEach(groups => {
                        groups?.items?.forEach(item => {
                            if (item?.forceItem === FORCE_ITEM.NO) {
                                addonsCount += 1;
                            }
                        });
                    });
                });
                return addonsCount + rentalsCount;
            } else {
                let addonsCount = 0;
                product?.consumables?.forEach(addon => {
                    addon?.specGroups?.forEach(groups => {
                        addonsCount += groups?.items?.length;
                    });
                });
                return addonsCount + product?.rentables?.length;
            }
        } catch (error) {
            console.error('Error calculting getTotalCount:', error);
        }
    };

    const handleIconClick = () => {
        const title = intl.formatMessage({ id: 'checkout:accessories-and-addons-forced-item-tooltip-title' });
        const content = () => {
            return (
                <>
                    <p className="required-tooltip-content">
                        {intl.formatMessage({
                            id: 'checkout:accessories-and-addons-forced-item-tooltip-content-text-1'
                        })}
                    </p>

                    <p className="required-tooltip-content">
                        {intl.formatMessage({
                            id: 'checkout:accessories-and-addons-forced-item-tooltip-content-text-2'
                        })}
                    </p>

                    <p>
                        {intl.formatMessage({
                            id: 'checkout:accessories-and-addons-forced-item-tooltip-content-text-3'
                        })}
                    </p>
                </>
            );
        };
        openModal(TOOLTIP_MODAL, { isOpen: true, title, content: content(), hideIcon: true });
    };

    const renderCardBody = (cartItem, consumableItem, index) => {
        const productAddonsAndAccessoriesCount = getTotalCount(consumableItem);
        return (
            <div
                role="button"
                aria-label={`${intl.formatMessage({
                    id: 'account:ac-common-click-here-msg'
                })} ${intl.formatMessage({ id: 'checkout:accessories-and-addons-makesure' })}  `}
                className="productCard-body"
                key={cartItem?.product?.sku || index.toString()}>
                <div className="productCard-productDetails">
                    <div data-testid={checkoutDatalocator.checkout_accessories_and_addons_producttitle}>
                        {cartItem?.product?.name}
                    </div>
                    <div className="productCard-priceAndquantity">
                        <small
                            className="productCard-quantity"
                            data-testid={checkoutDatalocator.checkout_accessories_and_addons_productquantity}>
                            {intl.formatMessage({ id: 'checkout-addOns:product-card-qty' })} {cartItem.quantity}
                        </small>
                        <small data-testid={checkoutDatalocator.checkout_accessories_and_addons_productprice}>
                            {currencyToLocale(cartItem?.prices?.row_total?.value, 2, 2)}
                        </small>
                    </div>
                    {productAddonsAndAccessoriesCount > 0 ? (
                        <div
                            className="productCard-availableItemsText"
                            data-testid={checkoutDatalocator.checkout_accessories_and_addons_itemsavailable_link}>
                            {productAddonsAndAccessoriesCount}{' '}
                            {intl.formatMessage({ id: 'checkout-addOns:product-card-itemsAvailable' })}
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                {productAddonsAndAccessoriesCount > 0 && (
                    <div className="productCard-rightArrow">
                        <ArrowRight />
                    </div>
                )}
            </div>
        );
    };

    const selectedAddonsContent = (addOnsObject, priceLabel) => {
        return (
            <li key={`${addOnsObject?.rentalClass}${addOnsObject?.name}`}>
                <div className="addons">
                    <div className="addons-title-container">
                        <span className="addons-title">{addOnsObject.name}</span>
                        {isP2PUser && addOnsObject?.isForced && (
                            <Button
                                dataTestid={checkoutDatalocator.checkoutForcedItemRequired}
                                className="toolTipIcon addons-required-btn"
                                tabIndex="0"
                                onClick={handleIconClick}>
                                {intl.formatMessage({ id: 'addons:required-btn-label' })}
                                <InfoIcon16 aria-hidden="true" />
                            </Button>
                        )}
                    </div>
                    <div className="addons-content">
                        <span className="addons-content-qty">
                            {intl.formatMessage({ id: 'addons:qty-label' })} {addOnsObject.qty}
                        </span>
                        <span>
                            <span className="addons-content-pricestatic">{priceLabel}</span>
                            <span className="addons-content-pricedynamic">
                                {isP2PUser && addOnsObject?.isForced && addOnsObject.price === 0
                                    ? intl.formatMessage({ id: 'addons:included-force-item-label' })
                                    : currencyToLocale(addOnsObject.qty * addOnsObject.price, 2, 2)}
                            </span>
                        </span>
                    </div>
                </div>
                <div className="separator"></div>
            </li>
        );
    };

    const renderBottomCard = product => {
        let rentalsLabel = intl.formatMessage({ id: 'addons:rentals-price-label' });
        let addonsLabel = intl.formatMessage({ id: 'addons:addons-price-label' });
        const { showBottomContent, rentalList, addonsList } = renderBottomContentHandler(product);

        if (showBottomContent) {
            return (
                <div className="bottomCardContent">
                    <div className="bottomCardContent-rectangleSeparator">
                        <CheckMarkCircle />
                        <span className="bottomCardContent-rectangleSeparator-content">
                            {intl.formatMessage({ id: 'addons:items-added-label' })}
                        </span>
                    </div>

                    <ul className="accessoryaddonlist">
                        {Object.entries(rentalList).map(([key, value], i) => {
                            return selectedAddonsContent(value, rentalsLabel);
                        })}
                        {Object.entries(addonsList).map(([key, value], i) => {
                            return selectedAddonsContent(value, addonsLabel);
                        })}
                    </ul>
                </div>
            );
        }
        return <></>;
    };

    const handleIUnderstand = async () => {
        sendEvents();
        setIsSkipAlertModalOpen(false);
        if (consumables?.saveAndContinue) {
            await getEstimates('', 0, '', '', false, cart?.availableCartItems);
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.IS_ESTIMATES_LOADING);
        }
        let accessoriesObj = getTotalFromConsumables(selected);
        dispatch({ type: UPDATE_CONSUMABLES, key: 'orderSummaryRentals', value: { ...consumables.selected } });
        dispatch({ type: UPDATE_CONSUMABLES, key: 'selectedRentalQtyAndPrice', value: { ...accessoriesObj } });
        dispatch({ type: UPDATE_CONSUMABLES, key: 'saveAndContinue', value: false }); // should be cleared
        stepChange();
    };

    const handleCloseModal = () => {
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                `${userType.toLowerCase()} select`,
                intl.formatMessage({ id: 'checkout:back-to-accessories-and-add-ons' }).toLowerCase()
            );
        } catch (error) {
            logError(error, false, 'handleCloseModal');
        }
        setIsSkipAlertModalOpen(false);
    };

    const onSkipAddingItems = () => {
        ecommerceAnalytics();
        setIsSkipAlertModalOpen(true);
    };
    const fetchImageURL = product => {
        let url = '';
        cart?.availableCartItems.map(item => {
            const catID = Number(item?.product?.sku.substring(0, 3));
            const classID = Number(item?.product?.sku.substring(3, 7));
            if (catID.toString() === product.cat && classID.toString() === product.class) {
                url = item?.product?.thumbnail?.url
                    ? makeUrl(item?.product?.thumbnail.url, {
                          type: 'image-product',
                          width: imageWidth,
                          height: imageHeight
                      })
                    : '';
            }
        });
        return url;
    };

    const renderEditableView = () => {
        return (
            <>
                {isLoading && (
                    <div className={'orderSummaryLoader'}>
                        <LoadingIndicator />
                    </div>
                )}
                <div className="accessories-head">
                    <span
                        className="accessories-head__makeSureText"
                        data-testid={checkoutDatalocator.checkout_accessories_and_addons_makesureyouhave_label}>
                        {intl.formatMessage({ id: 'checkout:accessories-and-addons-makesure' })}
                    </span>
                    <HelplineNumber
                        customClass={'accessories-head__needHelpSection'}
                        message={intl.formatMessage({ id: 'checkout:accessories-and-addons-need-help-text' })}
                        textDataLocator={checkoutDatalocator.checkout_accessories_and_addons_needhelp_label}
                        phonenumberDataLocator={checkoutDatalocator.checkout_store_phoneNumber}
                        phoneNumber={phoneNumber}
                    />
                </div>
                <div className="accessories-section">
                    {isAccessoriesAddonsAvailable() &&
                        consumables?.data?.map(consumableItem => {
                            if (consumableItem?.consumables?.length > 0 || consumableItem?.rentables?.length > 0) {
                                const imageURL = fetchImageURL(consumableItem);
                                return cart?.availableCartItems?.map((cartItem, index) => {
                                    const catID = Number(cartItem?.product?.sku.substring(0, 3));
                                    const classID = Number(cartItem?.product?.sku.substring(3, 7));
                                    if (
                                        catID.toString() === consumableItem.cat &&
                                        classID.toString() === consumableItem.class
                                    ) {
                                        const accessoriesAndAddons = {
                                            equipmentInfo: {
                                                sku: cartItem?.product?.sku,
                                                name: cartItem?.product?.name,
                                                qty: cartItem.quantity,
                                                img: imageURL
                                            },
                                            equipmentItems: consumableItem
                                        };
                                        return (
                                            <ContentCard
                                                key={`${cartItem?.product?.sku}${consumableItem?.cat}`}
                                                cardSideContent={() => renderCardBody(cartItem, consumableItem, index)}
                                                cardBottomContent={() => renderBottomCard(consumableItem)}
                                                imageSource={imageURL}
                                                imageTestId={
                                                    checkoutDatalocator.checkout_accessories_and_addons_productimage
                                                }
                                                onCardClick={() => {
                                                    if (getTotalCount(consumableItem) > 0) {
                                                        onPressProductCard(accessoriesAndAddons);
                                                    }
                                                }}
                                            />
                                        );
                                    }
                                });
                            }
                        })}
                </div>
                <div className="accessories-cta">
                    {isRentalsOrAddonsSelected ? (
                        <Button
                            type="button"
                            onClick={onContinue}
                            className="button button-primary button-block"
                            buttonAriaLabel={intl.formatMessage({ id: 'checkout:save-continue-cta' })}
                            data-testid={checkoutDatalocator.checkout_continue_CTA}>
                            {intl.formatMessage({ id: 'checkout:save-continue-cta' })}
                        </Button>
                    ) : (
                        <Button
                            type="button"
                            onClick={onSkipAddingItems}
                            className="button button-primary button-block"
                            buttonAriaLabel={intl.formatMessage({ id: 'checkout:accessories-and-addons-skip-cta' })}
                            data-testid={checkoutDatalocator.checkout_accessories_and_addons_skip_addingitems_cta}>
                            {intl.formatMessage({ id: 'checkout:accessories-and-addons-skip-cta' })}
                        </Button>
                    )}
                </div>
                <AlertModalWithButtons
                    isOpen={isSkipAlertModalOpen}
                    title={intl.formatMessage({ id: 'checkout:accessories-and-addons-skip-title' })}
                    warningQuestion={intl.formatMessage({ id: 'checkout:accessories-and-addons-skip-warning' })}
                    handleContinueClick={handleIUnderstand}
                    continueButtonText={intl.formatMessage({ id: 'checkout:accessories-and-addons-skip-understand' })}
                    handleCancelClick={handleCloseModal}
                    cancelButtonText={intl.formatMessage({ id: 'checkout:accessories-and-addons-skip-go-back' })}
                    closeIconDataLocator={checkoutDatalocator.checkout_accessories_closeIcon}
                    continueButtonDataLocator={checkoutDatalocator.checkout_IUnderstand_link}
                    cancelButtonDataLocator={checkoutDatalocator.checkout_goback_link}
                    titleDataLocator={checkoutDatalocator.checkout_accessoreis_skipModal}
                    warningQuestionDataLocator={checkoutDatalocator.checkout_equipmentAndAccesories_link}
                />
            </>
        );
    };
    return (
        <EditableView
            children={renderEditableView()}
            currentStep={currentStep}
            currentTitle={intl.formatMessage({ id: 'checkout:accessories-and-addons-title' })}
            testId={checkoutDatalocator.checkout_accessories_and_addons_title}
        />
    );
};
export default memo(EditAccessoriesAndAddons);
